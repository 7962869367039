import { Row } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import {
  FooterSection,
  Extra,
  Title
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="50px" height="50px"/>
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <div>
              <Title>{t("Telefon")}</Title>
              <a href="tel:+38762-986-941">062-986-941</a>
              </div>
              <div>
              <Title>{t("Address")}</Title>
              <a href="!#">Sarajevo, BiH</a>
              </div>
              <div>
                <Title>{t(`Email`)}</Title>
                <a href="mailto:orto-dr.house@hotmail.com">orto-dr.house@hotmail.com</a>
                </div>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
             <SocialLink
                href="https://ortodrhouse.olx.ba/"
                src="olx.png"
              />
            <SocialLink
                href="https://priluskicnedim.netlify.app"
                src="developer-logo.svg"
              />
              <SocialLink
                href="https://www.facebook.com/ortodrhouse.olx.ba/"
                src="facebook.svg"
              />
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
